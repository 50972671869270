<template>
  <AppLayout>
    <!-- 轮播图 -->
    <HomeBanner />
    <!-- 行业选择 -->
    <!-- <HomeScene /> -->
    <!-- 效能硬件 -->
    <!-- <HomePerformance /> -->
    <!-- 企业级用户解决方案 -->
    <!-- <HomeUser /> -->
    <!-- 售货保障 -->
    <!-- <HomeSales /> -->
    <!-- IT 解决方案 -->
    <!-- <HomeComputer /> -->
    <!-- 认证机构 -->
    <!-- <HomeAuthentication /> -->
    <!-- 稳定伙伴 -->
    <!-- <HomeStability /> -->
    <!-- 领先品牌 -->
    <!-- <HomeBrand /> -->
    <!-- <Relation /> -->
    <!-- <HomeNew style="opacity: 0; margin-top: 18px" />
    <HardSoft />
    <bannerone /> -->
    <!-- <div class="container-top">
      <span>我们为您精选优质供应链，确保每一款产品都拥有高效性能</span>
    </div>
    <HomeSpecial />
    <HomeSpecial style="margin-bottom: 10px" />
    <HomeSalesGuarantee /> -->

	  <!--新UI首页-->
		<HomePageNew/>
  </AppLayout>
  <HomeBackup />
</template>
<script setup>
import { ref, onMounted } from "vue";
import { getIfLogin } from "@/api/login/index.js";
import AppLayout from "@/components/AppLayout";
import HomeBanner from "./components/HomeBanner";
//import HomeScene from "./components/HomeScene";
//import HomePerformance from "./components/HomePerformance";
//import HomeUser from "./components/HomeUser";
//import HomeSales from "./components/HomeSales";
//import HomeComputer from "./components/HomeComputer";
//import HomeAuthentication from "./components/HomeAuthentication";
//import HomeStability from "./components/HomeStability";
//import HomeBrand from "./components/HomeBrand";
// import Relation from "../../components/directives/Relation";
// import HomeNew from "@/views/home/components/HomeNew";
// import HardSoft from "@/views/home/components/HardSoft";
// import HomeSpecial from "@/views/home/components/HomeSpecial";
import HomeBackup from "@/views/home/components/HomeBackup";
// import bannerone from "@/views/home/components/bannerone";
// import HomeSalesGuarantee from "@/views/home/components/HomeSalesGuarantee";
//import emitter from "@/utils/eventBus";
import router from "@/router";
import HomePageNew from "@/views/home/components/HomePageNew.vue";

onMounted(() => {
  getIfLogin().then((res) => {
    if (res.code != 200) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        setTimeout(function () {
          router.go(0);
        }, 100);
      }
    }
  });
});
</script>
<style scoped>
/* .container-top {
  margin: 40px 0 0 0;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
} */
</style>
